<template>
    <div class="CashOut" :class="{ 'tabber-bottom': true }">
        <van-cell-group>
            <van-cell class="cell1" title="可提现金额">
                <template #label>
                    <div class="price"><span>¥</span>{{ pageData.cancash }}</div>
                    <div class="note">*暂不支持分批次提现操作</div>
                </template>
            </van-cell>
            <van-cell title="手续费：">
                <template #default>
                    <div style="width: 100%;text-align: left;">¥ {{ pageData.draw_fee }}</div>
                </template>
            </van-cell>
            <van-cell class="cell2" title="到账金额：">
                <template #default>
                    <div style="width: 100%;text-align: left; color: red;font-weight: 700;">¥ {{ pageData.recivecash}}</div>
                </template>
            </van-cell>
            <van-cell title="提现到">
                <template #default>
                    <div style="width: 100%;text-align: left;display: flex; align-item:center;">
                        <van-icon name="alipay" color="#1677ff" size="24px" /> 支付宝
                    </div>
                </template>
            </van-cell>
        </van-cell-group>

        <van-form ref="form">
            <van-field
                    v-model.trim="username"
                    name="支付宝姓名"
                    label="支付宝姓名"
                    placeholder="支付宝姓名"
                    maxlength="10"
                    :rules="[{ validator: nameValidator, message: '请填写支付宝姓名' }]"/>
            <van-field
                    v-model.trim="account"
                    name="account"
                    label="支付宝账号"
                    placeholder="支付宝账号"
                    :rules="[{ validator: accountValidator, message: '请填写支付宝账号' }]"
                    maxlength="100"/>
        </van-form>

        <div class="bottom-button">
            <van-button block round type="danger" @click="submit">确认提现</van-button>
        </div>
        <div class="tongyi">
            <van-icon name="checked" color="#07c160" />我已阅读并同意 <span @click="showXieyi = true">《共享经济合作伙伴协议》</span>
        </div>

        <van-cell-group>
            <van-cell title="提现说明">
                <template #label>
                    <div class="tixianshuom" v-html="pageData.draw_notice">

                    </div>
                </template>
            </van-cell>
        </van-cell-group>

        <van-popup v-model="showXieyi" closeable close-icon="close" position="bottom" :style="{ height: '70%' }" round>
            <div class="showServiceDiv">
                <div class="showServiceDiv_li">共享经济合作伙伴协议</div>
                <div class="showServiceDiv_content" v-html="pageData.corporation">

                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
    import {Toast} from "vant";

    export default {
        name: "AgentCashOut",
        data() {
            return {
                showXieyi: false,
                username: "",
                account: "",
                pageData: {
                    cancash: "0.00",
                    allcash: "0.00",
                    draw_fee: "0.00",
                    withdraw: "0.00"
                },
            };
        },
        created() {
            this.getData()
        },
        methods: {
            //效验
            nameValidator(val){
                let patt2 =  /^[\u4e00-\u9fa5]{2,10}$/
                return patt2.test(val)
            },
            accountValidator(val){
                let patt = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
                let patt2 = /^1[3|4|5|8]\d{9}$/

                return patt.test(val) || patt2.test(val)
            },

            //提交
            submit(){
                if(this.pageData.withdraw < 1){
                    Toast('提现金额最低1元')
                }else{

                    let res = this.$refs.form.validate()
                    Promise.all([
                        res
                    ]).then(res => {
                        const toast = this.$toast.loading({
                            duration: 0,
                            forbidClick: true,
                            message: "正在提交....",
                        });
                        this.$request.post(
                            "withdraw",{
                                account:this.account,
                                user_name:this.username,
                                money: parseFloat(this.pageData.cancash)
                            }
                        ).then(res => {
                            if(res.code != 1){
                                Toast(res.message)
                            }else{
                                toast.clear();
                                this.$dialog.alert({
                                    title: '提交成功',
                                    message: `您的￥${parseFloat(this.pageData.recivecash)}提现申请已提交，将于1-3个工作日汇入您的支付宝，请留意支付宝到账信息~`,
                                    confirmButtonText:'我知道了'
                                }).then(() => {
                                    this.$router.push({
                                        path:'/my/invita'
                                    })
                                });
                            }

                        })
                    })
                }

            },

            getData(){
                let toast = this.$toast.loading({
                    message: '加载中',
                    forbidClick: true,
                    overlay:true
                });
                this.$request.get(
                    "withdrawhome"
                ).then(res => {
                    console.log(res)
                    this.pageData = res.data
                    toast.clear()
                })
            }
        }
    };
</script>

<style scoped>
    .CashOut {
        box-sizing: border-box;
    }
    .cell1 {
        padding-top: 32px;
    }
    .cell2 {
        font-size: 18px;
    }
    .btn {
        margin: 16px;
    }
    .price {
        font-size: 48px;
        font-weight: 700;
        color: #000;
        display: block;
        margin: 32px 16px;
    }
    .price > span {
        font-size: 32px;
        padding-right: 10px;
    }
    .note {
        font-size: 10px;
        color: #ccc;
        display: block;
        margin: 32px 16px;
    }
    .bottom-button {
        width: 70%;
        text-align: center;
        display: block;
        margin: 0 auto;
        padding-top: 32px;
    }
    .tixianshuom > div {
        font-size: 12px;
        color: #ccc;
    }
    .tongyi {
        width: 100%;
        margin: auto 0;
        font-size: 10px;
        padding-top: 10px;
        color: #ccc;
        padding-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tongyi > span {
        color: #f39a0d;
    }
    .showServiceDiv {
        margin: 32px 16px 16px 16px;
        text-align: center;
    }
    .showServiceDiv_li {
        font-size: 16px;
        font-weight: bold;
        margin: 8px 0;
    }
    .showServiceDiv_content {
        text-align: left;
        font-size: 12px;
    }
</style>
